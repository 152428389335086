import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"mt-1",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VCard,{staticClass:"pa-5 white shadow1 theme_rounded",staticStyle:{"border":"2px solid var(--theme_dark) !important"}},[(_vm.$store.getters['report/loadingProfitLoss'])?_c(VProgressLinear,{staticStyle:{"width":"100% !important"},attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{staticStyle:{"font-weight":"bold","font-size":"25px"}},[_c('tr',[_c('td',[_vm._v("Total sale")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_sale))])]),_c('tr',[_c('td',[_vm._v("Total sale")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_vat))])]),_c('tr',[_c('td',[_vm._v("Total transport")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_transport))])]),_c('tr',[_c('td',[_vm._v("Total discount")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_discount))])]),_c('tr',[_c('td',[_vm._v("Total return amount")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_return_amount))])]),_c('tr',[_c('td',[_vm._v("Total damage amount")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_damage_amount))])]),_c('tr',[_c('td',[_vm._v("Total cost of sale")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.total_cost_of_sale))])]),_c('tr',[_c('td',[_vm._v("Gross profit")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.profitloss.gross_profit))])])])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }